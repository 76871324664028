<script>
import jQuery from "jquery";
import api from "@/api";

require("jquery-ui/ui/widgets/autocomplete.js");

export default {
  name: "GroupRegistration",
  props: ["logout"],
  data: function () {
    return {
      group_name: null,
      suggestion: null,
      skipping: 0,
    };
  },
  methods: {
    setGroupName() {
      this.group_name = "";
    },
    skip() {
      this.skipping = 1;
      const url = "/SkipStep/";
      api.Util.axios.post(url, { step_name: "GroupRegistration" }).then(() => {
        this.nextURL();
      });
    },
    nextURL() {
      if (!this.skipping) {
        localStorage.setItem("popup_message", "New Act added!");
      }

      var rc = localStorage.getItem("ReturnComponent");
      if (rc) {
        this.$router.push({ name: rc });
      } else {
        this.$router.push({ name: "RehearsalSpaces" });
      }
    },
    submit() {
      if (this.group_name == null || !this.group_name.length) {
        return;
      }
      var data = {
        name: this.group_name,
      };
      this.$store.dispatch("General", "updateLoading", true);

      const url = "/groups/";
      api.Util.axios
        .post(url, data)
        .then(() => {
          this.nextURL();
          this.$store.dispatch("General/updateLoading", false);
        })
        .catch((err) => {
          this.$store.dispatch("General/updateLoading", false);
          if (err?.response?.data?.responseJson?.name) {
            alert(
              "Group already exists. Contact a registered group member to ask to be added."
            );
          }
        });
    },
    google_connect() {
      alert("google_connect");
    },
    facebook_connect() {
      alert("facebook_connect");
    },
  },
  created: function () {
    var parent_ = this;
    jQuery(document).ready(function () {
      const url = "/groups/";
      api.Util.axios.get(url).then((response) => {
        const data = response.data;
        var source = [];
        var a = data;
        if (data.results) {
          a = data.results;
        }
        for (var index = 0; index < a.length; index++) {
          source.push(a[index].name);
        }

        jQuery("#group_name").autocomplete({
          source: source,
          select: function (event, ui) {
            parent_.group_name = ui.item.label;
          },
        });
      });
    });
  },
};
</script>

<template>
  <div id="GroupRegistration" class="container">
    <div class="row justify-content-center text-center">
      <div class="col-10" id="desc">
        <div class="overlay"></div>
        <div class="row">
          <h4 class="col-12 fw-bold">What's your stage name?</h4>
          <div class="col-12">
            Add an group that will be used to show studio managers who is making
            the booking.
          </div>
        </div>
      </div>
    </div>

    <form @submit.prevent="submit">
      <div class="row justify-content-center">
        <div class="col-10 group_form">
          <div class="row">
            <div class="col-sm-3 col-12">Group Name</div>
            <div class="col-sm-9 col-12">
              <input
                class="form-control"
                type="text"
                id="group_name"
                v-model="group_name"
                placeholder="e.g Juliani/Erico/Nairobi Horns/Shamsi Music"
                required
              />
            </div>
          </div>

          <div class="row" v-if="suggestion">
            <div class="offset-sm-3 col-sm-9 col-12">
              <div class="col-4">
                Use:
                <a
                  class="btn text-primary btn-outline-primary time-suggestion"
                  @click.prevent="setGroupName"
                  >{{ suggestion }}</a
                >
              </div>
            </div>
          </div>
        </div>

        <div class="col-10 text-center" id="saveArea">
          <div class="row justify-content-center">
            <div class="col-sm-6 col-12">
              <input
                id="savebtn"
                class="btn btn-success"
                type="submit"
                value="Save & Continue"
              />
            </div>

            <div class="col-sm-6 col-12">
              <div class="row justify-content-center">
                <div class="col-12">
                  <button class="btn text-dark" @click.prevent="skip">
                    I don't want to make bookings.
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<style lang="scss" scoped>
#savebtn {
  margin-bottom: 5px;
}
.time-suggestion {
  margin-right: 10px;
  margin-top: 10px;
  border-radius: 4px;
  cursor: pointer;
}
#GroupRegistration {
  .text-grey {
    color: #333;
  }
  padding-top: 10px;
  h4 {
    font-weight: bold;
  }
  #desc {
    position: relative;
    padding-top: 20px;
    padding-bottom: 20px;
    background: url("/static/images/African_traditional_music.jpg");
    background-position: 50% 50%;
    color: white;
    background-size: cover;
    background-repeat: none;
    .overlay {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      background: rgba(0, 0, 0, 0.5);
    }
  }
  #user_form,
  .group_form,
  #saveArea {
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
    background: white;
    & > div {
      margin-bottom: 5px;
    }
  }

  #group_form {
    margin-top: 20px;
  }
  .grey {
    color: #888;
  }
}
</style>
